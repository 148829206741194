import "./Intro.css"

function Intro() {
    return (
        <>
            <p id="intro">
                Welcome to my page! :) <br/><br/>
                I'm António Santos, toni-santos, or just toni, a 3rd year student of <br/>
                Informatics and Computation Engineering <a href="https://github.com/FEUP" target="_blank" rel="noreferrer">@FEUP</a>.<br/><br/>
                Take a look at this small website I made to introduce myself :)<br/><br/>
                A list of the available commands is available with the <b>help</b> command!
            </p>
        </>
    )
}

export default Intro;
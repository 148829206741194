function Socials() {
    return(
        <>
        <p id="desc">Here are my social media links!</p>
        <ul>
            <li><a href="https://twitter.com/lesslovable">twitter</a></li>
            <li><a href="https://www.linkedin.com/in/ant%C3%B3nio-santos-327a81219/">linkedin</a></li>
            <li><a href="https://github.com/toni-santos">github</a></li>
        </ul>
        </>
    )
}

export default Socials;
function Projects() {
    return (
        <>
            <p id="desc">Some personal and uni related projects I've done in the past!</p>
            <ul id="projli">
                <li><a href="https://github.com/toni-santos/toni-santos.github.io" target="_blank" rel="noreferrer" >Personal website</a></li>
                <li><a href="https://github.com/toni-santos/ldts-proj" target="_blank" rel="noreferrer" >Into the Breach Clone/Demake for Uni course</a></li>
                <li><a href="https://github.com/toni-santos/ltw-proj" target="_blank" rel="noreferrer" >Website built with HTML/CSS/PHP</a></li>
                <li><a href="https://github.com/toni-santos/lbaw-earworm" target="_blank" rel="noreferrer" >Website built with Laravel</a></li>
            </ul>
        </>
    )
}

export default Projects;
import './Ls.css'

function Ls() {
    return(
        <>
            <p className="file" id="top_file">.</p>
            <p className="file">projects.txt</p>
            <p className="file">socials.txt</p>
            <p className="file">intro.txt</p>
        </>
    )
}

export default Ls;